import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let req = request;
    
    if(request.url !== 'https://api.bluelytics.com.ar/v2/latest'){
      req = request.clone({
        withCredentials: true
      });
    }

    return next.handle(req);
  }
}
