export const LOCAL_STORAGE_USER_KEY = 'sismat-user'

export const URLS_WITHOUT_FOOTER = [
    '/iniciar-sesion',
    '/registro',
    '/computo',
    '/suscribirse',
    '/recuperar',
    '/costos/calculadora-de-materiales',
    '/usuarios/reset',
  '/usuarios/configurar-cuenta-institucional'
  ]

export const URLS_WITHOUT_NAVBAR_OPTIONS = [
  '/iniciar-sesion',
  '/registro',
  '/suscribirse',
  '/recuperar',
  '/usuarios/reset',
  '/usuarios/configurar-cuenta-institucional'
]

export const URLS_WITHOUT_DUE_WARNING = [
    '/iniciar-sesion',
    '/registro',
    '/suscribirse',
    '/recuperar',
    '/usuarios/mi-perfil'
  ]

export const REGIONS = [
  'Buenos Aires',
  'CABA',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Córdoba',
  'Corrientes',
  'Entre Ríos',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquén',
  'Río Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucumán'
]

export const PAYMENT_STATUS_APPROVED = 1;
export const PAYMENT_STATUS_PENDING = 2;
export const PAYMENT_STATUS_FAILURE = 3;

export const SEARCH_ACCENTS = {
  a: 'àáâãäåæ',
  c: 'ç',
  e: 'èéêëæ',
  i: 'ìíîï',
  n: 'ñ',
  o: 'òóôõöø',
  s: 'ß',
  u: 'ùúûü',
  y: 'ÿ'
};

export const SEARCH_ACCENTS_CHARS = /[aceinosuy]/g;

export const COMPUTE_LIMIT_QUOTA = 50;
export const USERTASKS_LIMIT_QUOTA = 40;